




interface IProps {
    src:string;
    className?:string;
    id?:string;
};

const Icon = ({src,className,id}:IProps) => {
    return (
        <div id={id} className="cursor-pointer">
            <img src={src} className={`w-6 ${className}`}/>
        </div>
    );
};

export default Icon;
import { useState } from 'react'

interface InputProps {
    title:string;
    placeholder:string;
    error?:any;
    value?:any;
    onChange?:any;
    id?:string;
    type?:string;
    className?:string;
};

const Input = ({title,placeholder,error,value,onChange,id,type,className}:InputProps) => {
    return (
        <div className="flex flex-col w-full">
            <span className="font-nib text-[14px] lg:text-[16px] 2xl:text-[18px] text-main-black">{title}</span>
            {type==='password'?
            <SecureTextEntry id={id} value={value} onChange={onChange} type={type} className={className} placeholder={placeholder}/>
            :<div className='flex flex-row items-center w-full !rounded-lg  mt-1 bg-white overflow-hidden'>
                <input  id={id} name={id} value={value} onChange={onChange}  className="w-full font-mod text-[14px] lg:text-[16px] 2xl:text-[20px] px-[15px] 2xl:px-[17px] pt-[16px] 2xl:pt-[18px] pb-[15px] 2xl:pb-[17px] outline-bg-col outline-0" type={type} placeholder={placeholder} />
            </div>}
            {error&&<span className='ml-4 text-[13px] lg:text-[15px] 2xl:text-[17px] mt-[1px] text-higlight-col font-mod'>{error}</span>}
        </div>
    );
};

const SecureTextEntry = ({id,value,onChange,placeholder,className}:{className?:string,id?:string,value?:any,onChange?:any,type?:string,placeholder:string}) => {

    const [isHidden, setHidden] = useState(true);

    const  _toggle_hidden = () => {
        setHidden(!isHidden);  
    };

    return (
        <div className={`flex flex-row items-center w-full !rounded-lg  mt-1 bg-white overflow-hidden ${className}`}>
                <input  type={isHidden?'password':'text'}  id={id} name={id} value={value} onChange={onChange}  className="w-full font-mod text-[14px] lg:text-[16px] 2xl:text-[20px] px-[15px] 2xl:px-[17px] pt-[16px] 2xl:pt-[18px] pb-[15px] 2xl:pb-[17px] outline-bg-col outline-0" placeholder={placeholder} />
                {
                    isHidden?
                    <img onClick={_toggle_hidden} src={require('../../assets/icons/eye.png')} className="w-4 h-4 cursor-pointer mr-3" />
                    :
                    <img onClick={_toggle_hidden} src={require('../../assets/icons/hide.png')} className="w-4 h-4 cursor-pointer mr-3" />
                }
        </div>
    )
};  

export default Input;
import post from "../../utils/requests/post";
import { ILoginResponse, IValidateTokenResponse } from "../../utils/types/auth";
import { getAuth,signInWithCustomToken,signOut } from "firebase/auth";
import { _fb_app_ } from "../../app";
import axios from "axios";

const url = process.env.REACT_APP_API_URL||"";
const login = (info:{email:string,password:string},remember_me?:boolean):Promise<ILoginResponse> => {
    return new Promise(async(resolve,reject)=>{
        try {   
            const d = await axios.post(url+'auth/login',{...info,remember_me});
            const data = d.data;
            if(data.error){
               return reject(data);
            };
            const auth = getAuth(_fb_app_);
            const user = await signInWithCustomToken(auth, data.firebaseToken);
            resolve(data);
        }catch(e){  
            reject(e);
        };
    });
};


const validate_token = (token:string):Promise<IValidateTokenResponse> => {
    return new Promise(async(resolve,reject)=>{
        try{
            const data = await post<IValidateTokenResponse>(`invitation/validate/token`,{token});
            resolve(data);
        }catch(e){
            reject(e);
        };
    });
};


const accept_invitation_learner = (token:string,password:string,passwordConfirm:string):Promise<any> => {
    return new Promise(async(resolve,reject)=>{
        try{
            const data = await post<any>(`invitation/accept`,{token,password,passwordConfirm});
            resolve(data);
        }catch(e){
            reject(e);
        };
    });
};

const change_password = (email:string,password:string,passwordConfirm:string,token:string) => {
    return new Promise(async(resolve,reject)=>{
        try{
            const data = await post<any>('password/update',{email,password,passwordConfirm,token});
            resolve(data);
        }catch(e){
            reject(e);
        };
    });
};

const forgot_password_request = (email:string):Promise<any> => {
    return new Promise(async(resolve,reject)=>{
        try{
            const data = await post<any>(`password/reset`,{email});
            resolve(true);
        }catch(e){
            reject(e);
        };
    });
};

const logout = ():Promise<any> => {
    return new Promise(async(resolve,reject):Promise<any> =>{
        try{
            await post<any>('auth/logout');
            const auth = getAuth(_fb_app_);
            await signOut(auth);
            resolve(true);
        }catch(e){
            reject(e);
        };
    });
};

const end_tour = ():Promise<any> => {
    return new Promise(async(resolve,reject):Promise<any> =>{
        try{
            await post<any>('users/end-tour');
            resolve(true);
        }catch(e){
            reject(e);
        };
    });
};

export {login,logout,end_tour,validate_token,accept_invitation_learner,forgot_password_request,change_password};
import { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import Button from '../../components/button';
import Input from '../../components/input';
import BgWrapper from '../../components/onboarding/BgWrapper';
import {m} from 'framer-motion';
import {object,string} from 'yup';
import validate_password from '../../utils/validate_password';
import { useFormik } from 'formik';
import { change_password, login } from '../../actions/auth';
import { toast } from 'react-toastify';
import { useAuth } from '../../utils/providers/auth.provider';
import { cohort_dashboard_path, default_path } from '../../utils/configs/paths';
import { useDispatch } from 'react-redux';
import { set_cohort_id } from '../../db/redux/features/cohort/cohort.slice';


const validation_schema = object().shape({
    email:string().email('Invalid Email').required('Email is required.'),
    password: string().required('Password should have at least 4 characters and include 1 each of uppercase character, lowercase character, numerical digit and special symbol (!?%$)').min(3, 'Too Short!').test({message:'Password should have at least 4 characters and include 1 each of uppercase character, lowercase character, numerical digit and special symbol (!?%$)',
        test:(v:any)=>validate_password(v)}),
    confirm_password: string().required("Your password doesn't match")
}); 


interface INewPasswordForm {
    email:string;
    password:string;
    confirm_password:string;
};



const NewPassword = () => {
    const [loading,setLoading] = useState(false);
    const [params,setParams] = useSearchParams();
    const token = params.get('token')?.replaceAll(" ",'+');
    const auth = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const _handle_submission = async(values:INewPasswordForm) => {
        try{
            const {password,confirm_password,email} = values;
            if(password!==confirm_password){
                formik.setFieldError('confirm_password',"Your password doesn't match");
                return false;
           };
           setLoading(true);
           const res = await change_password(email,password,confirm_password,token||'');
           const login_data = await login({email:email||'',password:password});
           await auth.login(login_data);
           formik.setSubmitting(false);
           if(login_data.user.cohorts.length===1){
               dispatch(set_cohort_id({id:login_data.user.cohorts[0].id,title:login_data.user.cohorts[0].title?login_data.user.cohorts[0].title:''}));
               navigate(`/${cohort_dashboard_path}/${login_data.user.cohorts[0].id}`,{replace:true});
               return true;
           };
           navigate(default_path,{replace:true});
        }catch(e){
            // console.log(e);
             setLoading(false);
            toast.error('Something went wrong, Please try again.', {position: "top-center", autoClose: 2500, hideProgressBar: false, closeOnClick: true,  pauseOnHover: true, draggable: true, progress: undefined});
        };
    }

    const formik = useFormik({
        initialValues:{
            email:'',
            password:'',
            confirm_password:''
        },
        onSubmit:_handle_submission,
        validationSchema:validation_schema 
    });

    return (
       <BgWrapper>
            <m.div initial={{opacity:0}} exit={{opacity:0}}  transition={{duration:.2}} animate={{opacity:1}} key={'content'} layout className={`w-full h-full flex justify-center  items-center flex-col ${loading?'pointer-events-none':'pointer-events-auto'}`}>
                <img alt="logo" src={Logo} className="w-32 lg:w-44 2xl:w-48"/>
                <h1 className="font-nib text-[19px] lg:text-[27px] 2xl:text-[29px]  text-main-black mt-7">Reset password</h1>
                <span className="font-mod text-[15px] lg:text-[18px] 2xl:text-[20px] text-main-black w-full lg:w-7/12 xl:w-5/12 2xl:w-4/12 text-center px-5 lg:px-0">Please enter a new password to use for this account.</span>
                <form id='reset_password' onSubmit={formik.handleSubmit}  className="md:w-[60%] lg:w-full w-full flex flex-col items-center mt-7 px-5 lg:px-0"> 
                    <div className="flex  w-full lg:w-7/12 xl:w-5/12 2xl:w-4/12 flex-col space-y-5">
                        <Input value={formik.values.email} onChange={formik.handleChange} type='email' id="email" title='Email Address' placeholder='example@example.com' />
                        <Input type='password' id="password" error={formik.touched.password && formik.errors.password} value={formik.values.password} onChange={formik.handleChange} title='Password' placeholder='Enter your new password' />
                        <Input type='password' id="confirm_password" error={formik.touched.confirm_password && formik.errors.confirm_password} value={formik.values.confirm_password} onChange={formik.handleChange} title='Confirm Password' placeholder='Re-enter your password' />
                    </div>

                    <div className="w-full lg:w-7/12 xl:w-5/12 2xl:w-4/12 mt-6">
                        <Button loading={loading} id="reset_password" title='Set New Password'/>
                    </div>
                </form>
                <span className="font-mod  text-[15px] lg:text-[19px] text-main-black mt-6">Having Problems? <Link to={'/contact-us'} className="">
                    <span className="font-mod  text-[15px] lg:text-[19px] text-tx-gray underline cursor-pointer">Contact us</span>
                </Link></span>
            </m.div>
       </BgWrapper>
    );
};

export default NewPassword;
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// import { IChatUserList } from '../../../../utils/types/chat';
import { IKnowledgebase } from '../../../../utils/types/cohorts';
import { ITempState } from '../../../../utils/types/temp';
// import remove from 'lodash/remove';



const initialState: ITempState = {
    knowledge_base:null,
    selected_cohort_id:null,
    members:[],
    unread_ping_count:0,
    unread_notification_count:0
}




export const tempSlice = createSlice({
    name: 'temp',
    initialState,
    reducers: {
        set_kb:(state,action:PayloadAction<IKnowledgebase>)=>{
            state.knowledge_base = action.payload;
        },
        remove_kb:(state,action?:any)=>{
            state.knowledge_base = null;
            state.members = [];
        },
        set_members:(state,action:PayloadAction<any[]>)=>{
            state.members = action.payload;
        },
        set_ping_count:(state,action:PayloadAction<number>)=>{
            state.unread_ping_count = action.payload;
        },
        set_notification_count:(state,action:PayloadAction<number>)=>{
            state.unread_notification_count = action.payload;
        },
        decrease_notification_count:(state,action?:any)=>{
            state.unread_notification_count = state.unread_notification_count - 1;
        },
        // set_cohort_id:(state,action?:any) => {
        //     state.selected_cohort_id = action.payload
        // },
        // remove_cohort_id:(state,action?:any)=>{
        //     state.selected_cohort_id = null;
        // }
    }
  });
  
  // Action creators are generated for each case reducer function
  export const { set_kb,remove_kb,set_members,set_ping_count,set_notification_count,decrease_notification_count } = tempSlice.actions;
  
  export default tempSlice.reducer;
  
  
  
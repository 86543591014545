import { Link, useNavigate } from "react-router-dom";
import { notification_path } from "../../utils/configs/paths";
import Icon from "../icon";
import { Disclosure, Transition } from '@headlessui/react';
import Counter from "../counter";
import { get_notifications, set_read, get_unread_count } from "../../actions/notifications";
import { INotification } from "../../utils/types/notification";
import React, { useEffect, useMemo, useRef, useState } from "react";
import take from 'lodash/take';
import get_notification_path from "../../pages/notifications/get_notification_path";
import Loader from "../loader";
import { addHours, format, formatRelative } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../db/redux/store";
import { set_notification_count } from "../../db/redux/features/temp/temp.slice";




const NotificationPopup = () => {

    const [data,setData] = useState<INotification[]>([]);
    const [loading,setLoading] = useState(true);


    const [x,setX] = useState(0);
    // const [unreadCount,setUnreadCount] = useState(0);
    const unreadCount = useSelector((state:RootState)=>state.temp.unread_notification_count);
    const dispatch = useDispatch();

    const setUnreadCount = (x:number) => {
        dispatch(set_notification_count(x));
    }

    const ref = useRef<any>(null);
    const ref_icon = useRef<any>(null);
    const ref_container = useRef<any>(null);


    const _handle_click_outside = (event:any) => {
        if(event.target.id===ref_icon.current.id){
            return;
        };
        if (ref.current && !ref.current.contains(event.target)) {
            ref_icon.current.click();
        }
    };

    const convertRemToPixels = (rem:number) =>  {    
        return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
    }


    const navigate = useNavigate();

    useEffect(()=>{
        get();
        get_x();
        document.addEventListener('click', _handle_click_outside, true);
        window.addEventListener('resize', get_x);
        return () => {
            window.removeEventListener('resize', get_x);
            document.removeEventListener('click', _handle_click_outside, true);
        };
    },[]);

    const get_unread = async() => {
        try{
            const result:number = await get_unread_count();
            setUnreadCount(result);
        }catch(e){

        };
    }
    get_unread(); // put this here, so that the unread num would constantly be updated

    const get_x = () => {
        const offset = ref_icon.current.offsetLeft;
        const percentage = convertRemToPixels(25);
        setX(offset-percentage);
    };

    const get = async() => {
        try{
            const {items} = await get_notifications(1);
             setData(take(items,5));
             setLoading(false);
        }catch(e){

        };
    };


    const _on_click = (type:string,id:string,cohortId:string,referenceObject:any,read:boolean,no_id:string) => {
        const p = get_notification_path(type,cohortId,id,referenceObject?.zoomLink);
        if(!read){
            set_read(no_id);
            setUnreadCount(unreadCount > 0 ? unreadCount-1 : 0);
        };
        if(p){
            navigate(p);
        };
    };

    const _render_rows = (x:INotification) => {
        return <Row onClick={_on_click} {...x} key={x.id}/>;
    };

    const _check_fetch = () => {
        if(data.length===0){
            setLoading(true);
            get();  
        };
    };

    const _remove_items = () =>{
        setData([]);
    }

    return (
        <Disclosure>
            <Disclosure.Button className={'relative'} ref={ref_icon}>
                <Icon id="notification_user_count" src={require('../../assets/icons/notification.png')}/>
                <Counter c="!w-6 !h-6 absolute -top-4 -right-2" t="!text-[12px] leading-[8px]" count={unreadCount}/>
            </Disclosure.Button>

            <Transition afterLeave={_remove_items} afterEnter={_check_fetch} ref={ref_container} className={'absolute w-[25rem]'} style={{left:x}}  enter="transition duration-100 ease-out" enterFrom="transform scale-95 opacity-0" enterTo="transform scale-100 opacity-100" leave="transition duration-75 ease-out" leaveFrom="transform scale-100 opacity-100" leaveTo="transform scale-95 opacity-0">
                <Disclosure.Panel className={'w-full'}>
                       <div ref={ref} className="rounded-2xl overflow-hidden drop-shadow-lg w-full absolute right-0 mt-7">
                            <div className="bg-fill w-full flex items-center justify-between">
                                <div className="w-full flex items-center px-4 space-x-1">
                                    <Counter count={0}/>
                                    <span className="text-white font-nib text-[20px] leading-[18px]">New Notifications</span>
                                </div>
                                <Disclosure.Button className="absolute right-0 px-5 cursor-pointer">
                                    <img alt="" src={require('../../assets/icons/bar_icons/close_white.png')} className="w-4 h-4"/>
                                </Disclosure.Button>
                                <img alt="" src={require('../../assets/bg/bg_pop_up.png')} className="w-24"/>
                            </div>
                            <div className="p-4 bg-white w-full space-y-3">
                                 {
                                    loading?
                                        <div className="w-full h-44 flex justify-center items-center">
                                            <Loader />
                                        </div>
                                    :data.length===0?
                                        <div className="flex w-full justify-center items-center h-32">
                                                <span className="font-nib text-lg text-main-black opacity-70">No notifications</span>
                                        </div>
                                    :data.map(_render_rows)}
                            </div>
                            <Disclosure.Button as={Link} to={notification_path} className="w-full p-3 border-t border-tx-gray flex items-center justify-center bg-white cursor-pointer border-opacity-70">
                                    <span className="text-gold font-nib text-lg">See All Notifications</span>
                            </Disclosure.Button>
                        </div>
                </Disclosure.Panel>
            </Transition>

        </Disclosure>
    );
};



const Row = ({message,title,notificationDate,read,onClick,id,referenceId,cohortId,referenceObject,type}:INotification&{onClick:any}) =>{

    const _on_click = () => {
        // if(!referenceId){
        //     return;
        // };
        onClick(type,referenceId,cohortId,referenceObject,read,id);
    };

    const displayDate = useMemo(() => {
        if (new Date() > addHours(new Date(notificationDate), 1)) {
            return format(new Date(notificationDate), 'dd MM yyyy - hh:mm aaaa');
        }
        return formatRelative(new Date(notificationDate), new Date());
    }, [notificationDate]);

    return (
        <Disclosure.Button onClick={_on_click} className={`flex w-full flex-col ${read?'opacity-70':''}`}>
            <div className="w-full flex items-center flex-row justify-between">
                <div className="flex flex-row items-center w-full space-x-2">
                    <span className="font-nib text-main-black text-base truncate grow text-left">{title}</span>
                    <span className='font-mod text-[12px] leading-[16px] text-main-black opacity-70 whitespace-nowrap'>{displayDate}</span>
                </div>
                {!read&&<div className='w-[10px] h-[10px] bg-ac rounded-full'/>}
            </div>
            <span className="text-main-black font-mod text-sm leading-[14px] -mt-0.5 text-left">{message}</span>
        </Disclosure.Button>
    )
};


export default React.memo(NotificationPopup);
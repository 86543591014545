import { useEffect, useState } from "react";
import ReactPlaceholder from "react-placeholder/lib";
import { get_profile_details_by_id } from "../../actions/profile";
import PingButton from "../../common/chat/components/ping_button";
import ProfilePicture from "../../common/profile_picture";
import { IProfileDetails } from "../../utils/types/profile";
import {m} from 'framer-motion';
import get_time from "../../utils/get_time";

// const moment = require('moment-timezone');

// const getZoneFromOffset = (offsetString:any) => moment.tz.names().filter((tz:any) => moment.tz(tz).format('Z') === offsetString);


// const get_time = (of:string) => {
//     const _split = of.split(" ")[0];
//     const offset = _split.replace("(","").replace(")","").replace("UTC","");

//     const _to = getZoneFromOffset(offset)[0];
//     const _cr = moment.tz.guess();

//     const cd    = moment.tz(new Date(), _cr);
//     const up = cd.clone().tz(_to);

//     const ahead = moment().tz(_to).hour() - new Date().getHours();
//     const append = ahead>0?`(${ahead} ahead)`:'';
//     return `${up.format("hh:mm a")} GMT${offset} ${append}`;
// };

const ProfileDetails = ({closeModal,id}:{closeModal:any,id:string}) => {

    const [data,setData] = useState<IProfileDetails|null>(null);
    const [loading,setLoading] = useState(true);

    const get = async() => {
        try{
            const data = await get_profile_details_by_id(id);
            setData(data);
            setLoading(false);
        }catch(e){
        };
    };
    
    useEffect(()=>{
        get();
        window.scroll(0,0);

        document.body.style.overflow = 'hidden';
        return ()=> {
            document.body.style.overflow = 'unset'
        };
    },[]);

    return (
        <div onClick={closeModal} style={{zIndex:1000}} className="flex items-center justify-center absolute w-full h-full bg-main-black bg-opacity-70 left-0 right-0 top-0 bottom-0 ">
                <div className="bg-white rounded-md max-h-3/5 w-[26%] overflow-scroll p-7 px-10">
                     {
                        loading?
                        <m.div initial={{opacity:0}} transition={{duration:.2}} animate={{opacity:1}}  exit={{opacity:0}}  layout  className="w-full flex items-center flex-col">
                            <ReactPlaceholder className="bg-fill  rounded-full !w-28 !h-28 relative overflow-hidden" type='rect' ready={false}>
                            </ReactPlaceholder>
                            <ReactPlaceholder className="bg-fill  rounded-md mt-3 !w-full !h-8 relative overflow-hidden" type='rect' ready={false}>
                            </ReactPlaceholder>
                            <div className="h-40"/>
                        </m.div>
                        :
                        <m.div initial={{opacity:0}} transition={{duration:.2}} animate={{opacity:1}}  exit={{opacity:0}}  layout  className="w-full flex items-center flex-col">
                            <ProfilePicture id={data?.id||''} nameClass="!text-5xl" size={100} src={data?.profilePicture} name={`${data?.firstName} ${data?.lastName}`}/>
                            <span className="text-main-black font-nib text-lg mt-2 text-center">{data?.firstName} {data?.lastName}</span>
                            {data?.jobTitle||data?.companyName?<span className="text-main-black font-mod opacity-70 text-sm leading-[14px] text-center">{data?.jobTitle} {data?.companyName?'at':''} {data?.companyName}</span>:null} 
                            <div className="flex items-center space-x-2">
                                {data?.timezone?<span className="text-main-black font-mod opacity-70 text-sm leading-[14px] text-center mt-2">{get_time(data.timezone)}</span>:null}
                                {data?.pronouns?<span className="text-main-black font-mod opacity-70 text-sm leading-[14px] text-center mt-2">({data.pronouns})</span>:null}
                            </div>
                            <span className="font-nib text-main-black text-center text-base mt-4">{data?.aboutMe}</span>
                            <PingButton pingId={data?.pingId} textClass="font-mod text-lg" className="!mt-8" name={data?.firstName||''}/>
                           {data?.linkedIn?<a href={data?.linkedIn} target="_blank" className="my-3">
                                <span className="font-mod text-main-black opacity-70 text-base underline">LinkedIn</span>
                            </a>:null}
                        </m.div>
                     }
                </div>
        </div>
    );
};


export default ProfileDetails;



import Logo from '../assets/logo.png';


const Footer = () => {
    return (
        <div className='w-full flex pt-5 mt-10 pb-2 border-t-[1px] border-sep justify-center items-center'>
            <div className='flex justify-center items-center flex-col'>
                <img alt="logo" src={Logo} className="w-[5.5rem]"/>
                <span className='font-mod text-main-black text-sm mt-[1px]'>© All Rights Reserved 2022</span>
            </div>
        </div>
    )
};

export default Footer;
import { createSlice } from '@reduxjs/toolkit'



const initialState: {selected_cohort_id:string|null,cohort_title:string|null} = {
    selected_cohort_id:null,
    cohort_title:null
}




export const cohortSlice = createSlice({
    name: 'cohort',
    initialState,
    reducers: {
        set_cohort_id:(state,action?:any) => {
            state.selected_cohort_id = action.payload.id
            state.cohort_title = action.payload.title
        },
        remove_cohort_id:(state,action?:any)=>{
            state.selected_cohort_id = null;
            state.cohort_title = null;
        }
    }
  });
  
  // Action creators are generated for each case reducer function
  export const { set_cohort_id,remove_cohort_id } = cohortSlice.actions;
  
  export default cohortSlice.reducer;
  
  
  
import React from 'react';
import { Routes, Route,BrowserRouter, useLocation,useNavigationType,createRoutesFromChildren,matchRoutes } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
// import Header from './components/header';
import { initializeApp } from 'firebase/app';

import { getAuth } from "firebase/auth";


import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';
import AuthProvider from './utils/providers/auth.provider';
import { campfire_path, cohort_dashboard_path, cohort_schdule_path, forgot_password_path, knowledge_base_path, login_path, my_profile_path, new_password_path, nominate_co_workers, notification_path, ping_path, survery_path, typeform_path, welcome_path } from './utils/configs/paths';
import AuthWithSuspenseWrapper from './utils/wrappers/AuthWithSuspenseWrapper';
// import RequireAuth from './utils/require_auth';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import NewPassword from './pages/new_password';
import Welcome from './pages/welcome';
import firebase from './utils/configs/firebase';
import { ModalProvider } from "react-modal-hook";

// Load lazy
// const Pings = React.lazy(()=>import('./pages/pings'));
// const Notifications = React.lazy(()=>import('./pages/notifications'));
// const KnowledgeBase = React.lazy(() => import('./pages/knowledge_base'));
// const CohortSchedule = React.lazy(()=> import('./pages/cohort_schedule'));
// const Campfire = React.lazy(()=>import('./pages/campfire'));



const Home = React.lazy(()=>import('./pages/home'));
const Login = React.lazy(()=>import('./pages/login'));
const ForgotPassword = React.lazy(()=>import('./pages/forgot_password'));
const CohortDashboard = React.lazy(()=>import('./pages/cohort_dashboard'));
const KnowledgeBase = React.lazy(()=>import('./pages/knowledge_base'));

const CohortSchedule = React.lazy(()=>import('./pages/cohort_schedule'));

const Campfire = React.lazy(()=>import('./pages/campfire'));

const MyProfile = React.lazy(()=>import('./pages/my_profile'));

const Pings = React.lazy(()=>import('./pages/pings'));

const Notifications = React.lazy(()=>import('./pages/notifications'));

const ProfileDetails = React.lazy(()=>import('./pages/profile_details'));
const LeadershipSurvey = React.lazy(()=>import('./pages/360_leadership_survey'));
const NominateCoWorkers = React.lazy(()=>import('./pages/360_leadership_survey/nominate_co_workers'));
const TypeformView = React.lazy(()=>import('./pages/360_leadership_survey/typeform_view'));


Sentry.init({ 
    release:'dev-01',
    dsn: "https://51b4e31fd62f4ab6b239ac5d489840cf@o1362928.ingest.sentry.io/6654960",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            ),
        })
    ],
    tracesSampleRate: 1.0 
});

const _fb_app_ = initializeApp(firebase);

const auth = getAuth();

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
    
const App = () => {
    // @ts-ignore
    // console.log(window.Intercom);
    return (
            <BrowserRouter>
                    <ModalProvider>
                            <AuthProvider>
                                    <AnimatedRoutes />
                            </AuthProvider>
                    </ModalProvider>

                    <ToastContainer />
            </BrowserRouter>
    );
};  






const AnimatedRoutes = () => {

    return (
        <AnimatePresence exitBeforeEnter>
            <Routes>
                   <Route path={login_path} element={AuthWithSuspenseWrapper(Login)} />
                   <Route path={forgot_password_path} element={AuthWithSuspenseWrapper(ForgotPassword)} />
                   <Route path={new_password_path} element={AuthWithSuspenseWrapper(NewPassword)} />
                   <Route path={welcome_path} element={AuthWithSuspenseWrapper(Welcome)} />
                   <Route path="/" element={AuthWithSuspenseWrapper(Home)} /> 
                   <Route path={`${cohort_dashboard_path}/:id`} element={AuthWithSuspenseWrapper(CohortDashboard)} /> 
                   <Route path={`${knowledge_base_path}/:id/*`} element={AuthWithSuspenseWrapper(KnowledgeBase)} />
                   <Route path={`${campfire_path}/:id/*`} element={AuthWithSuspenseWrapper(Campfire)} />
                   <Route path={`${ping_path}/*`} element={AuthWithSuspenseWrapper(Pings)} />
                   <Route path={`${my_profile_path}`} element={AuthWithSuspenseWrapper(MyProfile)}/>
                   <Route path={`${cohort_schdule_path}/:id`} element={AuthWithSuspenseWrapper(CohortSchedule)}/>
                   <Route path={`${survery_path}/:id`} element={AuthWithSuspenseWrapper(LeadershipSurvey)}/>
                   <Route path={`${survery_path}/:id/${nominate_co_workers}/:step_no`} element={AuthWithSuspenseWrapper(NominateCoWorkers)}/>
                   <Route path={`${survery_path}/:id/${typeform_path}/:stage_no`} element={AuthWithSuspenseWrapper(TypeformView)}/>
                   <Route path={`${notification_path}`} element={AuthWithSuspenseWrapper(Notifications)}/>
             </Routes>


        </AnimatePresence>
    )
};


// const App:React.FC = () => {
//     return (
//         <BrowserRouter>

//         {/* <div className='sticky top-0 z-40'>
//              <Header />
//         </div>
//             <Routes>
//                 <Route index element={<Home />} />
//                 <Route path="notifications" element={Wrapper(Notifications)} />
//                 <Route path="cohort-schedule" element={Wrapper(CohortSchedule)} />
//                 <Route path="knowledge-base" element={Wrapper(KnowledgeBase)} />
//                 <Route path="campfire" element={Wrapper(Campfire)}>
//                         <Route path="thread/:thread_id" element={Wrapper(Campfire)} />
//                 </Route>
//                 <Route path="pings" element={Wrapper(Pings)} >
//                     <Route path=":chat_id" element={Wrapper(Pings)} />
//                     <Route path="thread/:thread_id" element={Wrapper(Pings)} />
//                 </Route>
//             </Routes> */}
//             <Routes>
//                 <Route element={Wrapper(Login)} />
//             </Routes>
//             <ToastContainer />
//         </BrowserRouter>
//     )
// };  

export {_fb_app_};
export default App;
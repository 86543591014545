import axios from './api';
const url = process.env.REACT_APP_API_URL||"";



export default <T>(what:string,params?:Record<string,string>):Promise<T> => {
    return new Promise(async(resolve,reject)=>{
        try {
            let query = "";
            if(params){
               query = "?"+new URLSearchParams(params).toString();
            };
            // console.log("called")
            const result = await axios.get(`${url}${what}${query}`);
            resolve(result.data);
        }catch(e){
            reject(e);
        };
    });
};
import { Link } from "react-router-dom";
import { ping_path } from "../../../utils/configs/paths";



const PingButton = ({name,className,textClass,pingId}:{pingId?:string,name:string,className?:string,textClass?:string}) => {
    if(!pingId){
        return null;
    };
    return (
          <Link to={`${ping_path}/${pingId||''}`} className={`bg-fill rounded-lg py-2.5 w-full flex items-center justify-center mt-2.5 cursor-pointer ${className}`}>
                <span className={`text-white font-mod text-sm line-clamp-1 ${textClass}`}>Ping {name}</span>
          </Link>
    )
};


export default PingButton;
import axios from "axios";
import { on_user_logout } from "../../db/redux/features/auth/auth.slice";
import { store } from "../../db/redux/store";
// import { useNavigate } from "react-router-dom";
const instance = axios.create({
    headers: {
      "Content-Type": "application/json",
    }
});


const url = process.env.REACT_APP_API_URL||"";


instance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err:any) => {
      const originalConfig = err.config;
      if (originalConfig.url !== "/auth/login" && err.response) {
        if ((err.response.status === 401||err.response.status===403) && !originalConfig._retry) {
           originalConfig._retry = true;
          try {
            const _tk = localStorage.getItem('tokens');
            const tokens =_tk?JSON.parse(_tk):{refreshToken:''};
            // console.log(tokens);
            const rs = await instance.post(`${url}auth/refresh`, {
                refreshToken: tokens.refreshToken
            });
            const { token } = rs.data;
            // console.log(rs);
            localStorage.setItem("tokens",JSON.stringify({...tokens,token:token}))
            return instance(originalConfig);
          } catch (_error:any) {
            // console.log(_error);
            if(_error.response.status===422){
              localStorage.removeItem("tokens");
              store.dispatch(on_user_logout());
              window.location.replace("/login");
              return Promise.reject("logout");
            };
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(err);
    }
);
  

instance.interceptors.request.use(
    (config:any) => {
        const _tk = localStorage.getItem('tokens');
        const tokens =_tk?JSON.parse(_tk):{token:''};
        config.headers["Authorization"] = `Bearer ${tokens.token}`; 
        return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);


export default instance;
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IChatUserList } from '../../../../utils/types/chat';
// import { IKnowledgebase } from '../../../../utils/types/cohorts';
// import { ITempState } from '../../../../utils/types/temp';
import remove from 'lodash/remove';



const initialState: {chat_list:IChatUserList[]} = {
    chat_list:[]
}




export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        add_new_temp_list:(state,action:PayloadAction<IChatUserList>)=>{
            state.chat_list.push(action.payload);
        },
        clear_tmp_list:(state,action?:any) =>{
            state.chat_list = [];
        },
        remove_tmp_list:(state,action:PayloadAction<string>) =>{
            remove(state.chat_list,(l:IChatUserList)=>l._id===action.payload);
        }
    }
  });
  
  // Action creators are generated for each case reducer function
  export const { add_new_temp_list,clear_tmp_list,remove_tmp_list } = chatSlice.actions;
  
  export default chatSlice.reducer;
  
  
  
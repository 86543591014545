// import React from 'react';
import Loader from './loader';

const Fallback = () => {
    return (
        <div className="w-full h-screen flex justify-center items-center">
            <Loader />
        </div>
    )
};


export default Fallback;
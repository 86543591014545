import React from "react";
import Counter from "../../counter";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { _fb_app_ } from "../../../app";
import { connect } from "react-redux";
import { RootState } from "../../../db/redux/store";
import { set_ping_count } from "../../../db/redux/features/temp/temp.slice";

class UnReadChatCount extends React.PureComponent<any,any>{

    private _unsub:any;
    db: any;
    constructor(props:any){
        super(props);
        this._unsub = null;
        this.db = getFirestore(_fb_app_);
        this.state = {
            count:0
        }
    };

    componentDidMount(): void {
        this._unsub = onSnapshot(doc(this.db, "pings", this.props.user.id), (doc) => {
            const data = doc.data();
            if(data){
                const count = data.totalUnreadCount;
                this.props.set_ping_count(count<0?0:count);
                this.setState({count:count<0?0:count});
            };
        });
    };

    componentWillUnmount(): void {
        this._unsub&&this._unsub();
    }

    render(): React.ReactNode {
        return (
            <Counter c="!w-6 !h-6 absolute -top-4 -right-2" t="!text-[12px] leading-[8px]" count={this.state.count}/>
        );
    };
};

const map_state_to_props = (state:RootState) =>{
    return {
        user:state.auth.user
    }
};

const map_dispatch_to_props = (dispatch:any) =>{
    return {
        set_ping_count:(count:number)=>dispatch(set_ping_count(count))
    }    
};

export default connect(map_state_to_props,map_dispatch_to_props)(UnReadChatCount);


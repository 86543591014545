import React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { logout } from '../actions/auth';
import { on_user_logout } from '../db/redux/features/auth/auth.slice';
import { clear_tmp_list } from '../db/redux/features/chat/chat.slice';
import { remove_cohort_id } from '../db/redux/features/cohort/cohort.slice';
import { default_path, forgot_password_path, login_path, new_password_path, welcome_path } from './configs/paths';
import { useAuth } from './providers/auth.provider';



const onboarding_paths = [login_path,forgot_password_path,new_password_path,welcome_path];

function RequireAuth({ children,replace }: { children?: JSX.Element|null,replace?:string }):any {
    let auth = useAuth();
    let location = useLocation();
    const dispatch = useDispatch();

    const _logout = () => {
      try{
             logout();
             dispatch(on_user_logout());
             dispatch(clear_tmp_list(null))
             dispatch(remove_cohort_id(null));
         }catch(e){}
     };

    if(location.pathname===welcome_path&&auth.user?.token){
      _logout();
      return <Navigate  to={{
        pathname:welcome_path,
        search:location.search
      }} state={{ from: location }}  replace />;
    };
    if(auth.user?.token&&onboarding_paths.includes(location.pathname)){
      return <Navigate to={default_path} state={{ from: location }}  replace />;
    };
    if (!auth.user?.token&&!onboarding_paths.includes(location.pathname)) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    };
    if(replace){
      return <Navigate to={`${replace}`} state={{ from: location }}  replace />;
    }
    if(!children){
      return <div/>
    };
    return children;
};


export default RequireAuth;
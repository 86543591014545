import ProfileWrapper from "../pages/profile_details/wrapper";


interface IProps {
    id:string;
    size?:number;
    oblong?:boolean;
    src?:string|null|undefined;
    name?:string;
    className?:string;
    nameClass?:string;
    nowrap?:boolean;
    children?:any;
};


const ProfilePicture = ({
    size = 42,
    oblong = true,
    src,
    name,
    className = "",
    nameClass = "",
    apppend = false,
    nowrap,
    id,
    children = null
} : IProps & { apppend?: boolean }) => {
    const width =  size;
    // const width = oblong ? Math.round(size / 1.2) : size;
    const d = (
        <div
            className={'border-[1px] border-fill rounded-full p-[2px] bg-white relative overflow-hidden flex justify-center items-center'}
            style={{
                width: `${width}px`,
                height: `${size}px`,
            }}
        >
            {src ? (
                // eslint-disable-next-line jsx-a11y/img-redundant-alt
                <img
                    alt={'profile picture'}
                    src={src}
                    className={'rounded-full'}
                    // className={newClassName}
                    // will explicitly set width and height, tailwind is buggy
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                    }}
                />
            ) : (
                <div className={`rounded-full justify-center items-center flex bg-fill`}
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <span
                        className={`font-mod text-auto leading-5 text-white ${nameClass} ${className?.includes('w-5')?'!text-base':''}`}
                    >
                        {apppend?'+':''}{get_name_letter(name || '',className?.includes('w-5') )}
                    </span>
                </div>
            )}
            { children }
        </div>
    );
    if (nowrap) {
        return d;
    };
    return (
        <ProfileWrapper id={id}>
            {d}
        </ProfileWrapper>
    )
};

const get_name_letter = (name:string,one?:boolean):string => {
    let _n = name.split(" ");
    _n.length = one?1:2;
    let to_return = '';
    _n.map((n:string|undefined)=>{
        if(n){
         to_return += n[0];
        };
    });
    return to_return.toUpperCase();
};

export default ProfilePicture;
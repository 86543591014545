import { Link } from "react-router-dom";
import { ping_path } from "../../../utils/configs/paths";
import Icon from "../../icon";
import UnReadChatCount from "./unread";




const ChatPopUp = () => {
    return (
        <div className="relative">
            <Link to={ping_path}>
                    <Icon id="ping_user_chat_count" src={require('../../../assets/icons/chat.png')}/>
            </Link>
            <UnReadChatCount />
        </div>
    )
};


export default ChatPopUp;
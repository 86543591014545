import get from "../../utils/requests/get";
import post from "../../utils/requests/post";
import { INotificationResponse } from "../../utils/types/notification";


const get_notifications = (page=1):Promise<INotificationResponse> => {
    return new Promise(async(resolve,reject)=>{
        try{
            const data = await get<INotificationResponse>(`notifications`,{page:`${page}`});
            resolve(data);
        }catch(e){
            reject(e);
        };
    });
};

const get_unread_count = (page=1):Promise<number> => {
    return new Promise(async(resolve,reject)=>{
        try{
            const data = await get<number>(`notifications/unread-count`,{});
            resolve(data);
        }catch(e){
            reject(e);
        };
    });
};

const mark_all_read = ():Promise<any> => {
    return new Promise(async(resolve,reject)=>{
        try{
            const data = await post(`notifications/read-all`);
            resolve(data);
        }catch(e){
            reject(e);
        };
    });
};


const set_read = (id:string):Promise<any> => {
    return new Promise(async(resolve,reject)=>{
        try{
            const data = await post(`notifications/${id}/read`);
            resolve(data);
        }catch(e){
            reject(e);
        };
    });
};

export {
    get_notifications,
    set_read,
    get_unread_count,
    mark_all_read
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAuthState } from '../../../../utils/types/auth';
import * as Sentry from "@sentry/react";

const initialState: IAuthState = {
  token:null,
  user:null,
  refreshToken:null
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    on_user_login:(state,action:PayloadAction<IAuthState>)=>{
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.refreshToken = action.payload.refreshToken;
      Sentry.setUser({ email: action.payload.user?.email });
    },
    on_user_logout:(state)=>{
        state.token = null;
        state.user = null;
        state.refreshToken = null;
        Sentry.setUser(null);
    },
    update_tour_state:(state) => {
      if(state.user){
          state.user.isTourDone = true;
      };
    },
    update_profile_picture:(state,action:PayloadAction<string>)=>{
       if(state.user){
          state.user.profilePicture = action.payload;
       };
    },
    update_user_name:(state,action:PayloadAction<{firstName:string,lastName:string}>)=>{
      if(state.user){
         state.user.firstName = action.payload.firstName;
         state.user.lastName = action.payload.lastName;
      };
    }
  },
});

// Action creators are generated for each case reducer function
export const { on_user_login,on_user_logout,update_tour_state,update_profile_picture,update_user_name } = authSlice.actions;

export default authSlice.reducer;




const configs = JSON.parse(process.env.REACT_APP_FIREBASE||"");
export default configs;
// {
//     apiKey: "AIzaSyDb_YGObvYEm4EiXJQnX33izx69mKJLnZU",
//     authDomain: "learner-hub-test.firebaseapp.com",
//     databaseURL: "https://learner-hub-test-default-rtdb.asia-southeast1.firebasedatabase.app",
//     projectId: "learner-hub-test",
//     storageBucket: "learner-hub-test.appspot.com",
//     messagingSenderId: "695154003309",
//     appId: "1:695154003309:web:43f4d8cb3aeaa42d519e64",
//     measurementId: "G-4L5X53XCVF"

//     // apiKey: "AIzaSyD60JWK9szP64glB9u28DsAKxVgETEu8SE",
//     // authDomain: "test-project-e6d99.firebaseapp.com",
//     // databaseURL: "https://test-project-e6d99-default-rtdb.asia-southeast1.firebasedatabase.app",
//     // projectId: "test-project-e6d99",
//     // storageBucket: "test-project-e6d99.appspot.com",
//     // messagingSenderId: "1046333972873",
//     // appId: "1:1046333972873:web:3711b3e9a76899d0590158",
//     // measurementId: "G-VY3GGE39N4"

// };
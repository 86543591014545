
import moment from 'moment-timezone';

const getZoneFromOffset = (offsetString:any) => moment.tz.names().filter((tz:any) => moment.tz(tz).format('Z') === offsetString);


export default  (of:string) => {
    try{
        const _split = of.split(" ")[0];
        const offset = _split.replace("(","").replace(")","").replace("UTC","");
    
        const _to = getZoneFromOffset(offset)[0];
        const _cr = moment.tz.guess();
    
        const cd    = moment.tz(new Date(), _cr);
        const up = cd.clone().tz(_to);
    
        const ahead = moment().tz(_to).hour() - new Date().getHours();
        const append = ahead>0?`(${ahead}h ahead)`:'';
        return `${up.format("hh:mm a")} GMT${offset} ${append}`;
    }catch(e){
        return '';
    }
}
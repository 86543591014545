import { useEffect,memo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { logout } from '../actions/auth';
import Logo from '../assets/logo.png';
import { on_user_logout } from '../db/redux/features/auth/auth.slice';
import { RootState } from '../db/redux/store';
// import Icon from './icon';
import ProfilePicture from './profile_picture';
import { Disclosure, Transition } from '@headlessui/react'
import { my_profile_path } from '../utils/configs/paths';
import { remove_cohort_id } from '../db/redux/features/cohort/cohort.slice';
import { clear_tmp_list } from '../db/redux/features/chat/chat.slice';
import NotificationPopup from './popups/notification';
import ChatPopUp from './popups/chat';


function classNames(...classes:any) {
    return classes.filter(Boolean).join(' ')
  }
  

const Header = () => {

    const user = useSelector((state:RootState)=>state.auth.user);
    const cohort_id = useSelector((state:RootState)=>state.cohort.selected_cohort_id);
    const navigation = useNavigate();

    const location = useLocation();
    const path = location.pathname.replace("/","").split("/")[0];
    const path_value = location.pathname.replace("/","").split("/")[1];


    const close_ref = useRef<any>(null);
    const container_ref = useRef<any>(null);



    const dispatch = useDispatch();


    const _handle_click_outside = (event:any) => {
        if(!close_ref.current){
            return;
        };
        if(event.target.id===close_ref.current.id){
            return;
        };
        if (container_ref.current && !container_ref.current.contains(event.target)) {
            close_ref.current.click();
        }
    };


    useEffect(()=>{
        document.addEventListener('click', _handle_click_outside, true);
        return () => {
            document.removeEventListener('click', _handle_click_outside, true);
        };
    },[]);

    const _logout = () => {
     try{
            logout();
            dispatch(on_user_logout());
            dispatch(clear_tmp_list(null))
            dispatch(remove_cohort_id(null));
        }catch(e){}
    };

    const _on_profile_click = () => {
        navigation(`/${my_profile_path}`);
    };

    const _on_logo_click = () => {
        navigation('/');
    };

    return (
        <Disclosure as="nav" className="w-full px-0 lg:px-[3rem] xl:px-[7.5rem] bg-bg-col">
            {({ open }) => (
                <>
                    <div className='flex w-full justify-between items-center border-b-[1px] border-sep md:pt-3 pt-3 pb-3 md:pb-[1.1rem] lg:pb-3 bg-bg-col px-4'>
                        <img onClick={_on_logo_click} alt="logo" src={Logo} className="w-16 md:w-[6rem] lg:w-[5rem] xl:w-[6.5rem] cursor-pointer"/>

                    {path&&<IconSection currentPath={path} value={cohort_id||path_value}/>}
                        <div className="flex flex-row items-center space-x-4">
                            {/* <Link to={notification_path}>
                                <Icon id="notification_user_count" src={require('../assets/icons/notification.png')}/>
                            </Link> */}
                            <NotificationPopup />
                            {/* <Link to={ping_path}>
                                <Icon id="ping_user_chat_count" src={require('../assets/icons/chat.png')}/>
                            </Link> */}
                            <ChatPopUp />

                            {/* <div className='hidden lg:flex' onClick={_on_profile_click}> */}
                                <Disclosure.Button  className="hidden lg:flex">
                                    <div id="profile_user_header">                                    
                                        <ProfilePicture size={42} id="" nowrap src={user?.profilePicture||''} nameClass={"text-sm"} className={'cursor-pointer'} name={`${user?.firstName} ${user?.lastName}`}/>
                                    </div>
                                </Disclosure.Button>
                            {path&&(
                                <Disclosure.Button className="lg:hidden inline-flex items-center justify-center">
                                {open?<img src={require('../assets/icons/bar_icons/close.png')} className="w-4 h-4"/>:<img src={require('../assets/icons/bar_icons/menu.png')} className="w-4 h-4"/>}
                                </Disclosure.Button>
                            )}
                        </div>
                    </div>
                    <Transition enter="transition duration-100 ease-out" enterFrom="transform scale-95 opacity-0" enterTo="transform scale-100 opacity-100" leave="transition duration-75 ease-out" leaveFrom="transform scale-100 opacity-100" leaveTo="transform scale-95 opacity-0">
                        <Disclosure.Panel className="hidden lg:flex">
                            <div ref={container_ref} className="px-4 py-4 w-[20rem] bg-white rounded-2xl drop-shadow-lg absolute right-0 -mt-2">

                                <div  className="flex items-center flex-row space-x-2">
                                    <ProfilePicture size={42} id="" nowrap src={user?.profilePicture||''} nameClass={"text-sm"} className={'w-[25px] h-[25px] cursor-pointer'} name={`${user?.firstName} ${user?.lastName}`}/>
                                    <div className='flex flex-col'>
                                        <span className='font-nib text-main-black text-sm leading-none'>{user?.firstName} {user?.lastName}</span>
                                        <span className='font-mod text-main-black text-xs'>{user?.email}</span>
                                    </div>
                                </div>
                                <Disclosure.Button id='close_btn_profile' ref={close_ref} className={'hidden'}>close</Disclosure.Button>
                                <div className='w-full h-[1px] bg-main-black opacity-50 my-3'/>
                                <DropDownButton onClick={_on_profile_click} title='My Profile' icon={require('../assets/icons/profile.png')}/>
                                <DropDownButton onClick={_logout} title='Log out' icon={require('../assets/icons/logout.png')}/>
                            </div>
                        </Disclosure.Panel>
                    </Transition>

                    {path&&
                    <Transition enter="transition duration-100 ease-out" enterFrom="transform scale-95 opacity-0" enterTo="transform scale-100 opacity-100" leave="transition duration-75 ease-out" leaveFrom="transform scale-100 opacity-100" leaveTo="transform scale-95 opacity-0">
                        <Disclosure.Panel className="lg:hidden">
                            <div className="py-3 bg-bg-col rounded-b-2xl drop-shadow-lg absolute right-0 left-0">
                                <div className='px-4 border-b border-b-sep pb-2'>
                                    <IconSectionMobile currentPath={path} value={path_value}/>
                                </div>
                                <div className="px-7 pt-3 pb-1">
                                    <Disclosure.Button as="div" onClick={_on_profile_click} className="flex items-center flex-row space-x-2 cursor-pointer">
                                        <ProfilePicture size={42} id="" nowrap src={user?.profilePicture||''} nameClass={"text-sm"} className={'w-[25px] h-[25px] cursor-pointer'} name={`${user?.firstName} ${user?.lastName}`}/>
                                        <div className='flex flex-col'>
                                            <span className='font-nib text-main-black text-sm leading-none'>{user?.firstName} {user?.lastName}</span>
                                            <span className='font-mod text-main-black text-xs'>{user?.email}</span>
                                        </div>
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </Transition>}
                </>
            )}
        </Disclosure>
      
    );
};


interface ItemsType {
    id:string;
    path:string;
    title:string;
};


const get_icon = (id:string,isSelected:boolean)=>{ 
    switch(id){
            case 'cohort':
                return isSelected?require('../assets/icons/bar_icons/home_selected.png'):require('../assets/icons/bar_icons/home.png');
            case 'campfire':
                return isSelected?require('../assets/icons/bar_icons/campfire_selected.png'):require('../assets/icons/bar_icons/campfire.png');
            case 'knowledge-base':
                return isSelected?require('../assets/icons/bar_icons/knowledge_base_selected.png'):require('../assets/icons/bar_icons/knowledge_base.png');
            case 'cohort-schedule':
                return isSelected?require('../assets/icons/bar_icons/cohort_schedule_selected.png'):require('../assets/icons/bar_icons/cohort_schedule.png');
        default:
            return null;
    };
};


const get_items = (value:string):any=> {
    return [
        {
            id:'cohort',
            path:`cohort/${value||''}`,
            title:'Home',
        },
        {
            id:'campfire',
            path:`campfire/${value||''}`,
            title:'Campfire',
        },
        {
            id:'knowledge-base',
            path:`knowledge-base/${value||''}`,
            title:'Knowledge',
        },
        {
            id:'cohort-schedule',
            path:`cohort-schedule/${value||''}`,
            title:'Schedule',
        },
    ];
};


const IconSectionMobile = ({currentPath,value}:{currentPath:string,value:any}) => {
    const items = get_items(value);
    const navigate = useNavigate();

    const _on_click = (path:string) => {
        navigate(path);
    };

    const _render_icons = (x:typeof items[0]) => {
        return <MobileIcon onClick={_on_click}  selected={currentPath===x.id} key={x.id} {...x}/>;
    };

    return (
        <div className='flex flex-col space-y-1'>
                {items.map(_render_icons)}
        </div>
    )
};

const IconSection = ({currentPath,value}:{currentPath:string,value:any}) => {

    const items = get_items(value);

    const _render_icons = (x:typeof items[0]) => {
        return <IconBtn selected={currentPath===x.id} key={x.id} {...x}/>;
    };
    return (
        <div className='hidden lg:flex flex-row items-center justify-center'>
                {items.map(_render_icons)}
        </div>
    )
};  

const DropDownButton = ({title,icon,onClick}:{title:string,icon:any,onClick:any}) => {
    return (
        <Disclosure.Button onClick={onClick} className="flex items-center hover:bg-bg-col py-3 px-3 rounded-lg cursor-pointer w-full">
            <img className="md:w-[1.2rem] lg:w-[1rem] xl:w-[1.2rem] w-[1.1rem] mr-2" src={icon} />
            <span className={`font-mod font-[1.1rem] text-main-black`}>{title}</span>
        </Disclosure.Button>
    )
};

const IconBtn = memo(({title,path,selected,id}:ItemsType&{selected:boolean}) => {
    return (
        <Link to={`/${path}`} className={`flex flex-row items-center cursor-pointer ${selected?'bg-br-gray':''} md:px-5 lg:px-4 xl:px-6 px-4 md:py-2 py-3 rounded-lg`}>
            <img src={get_icon(id,selected)} className="md:w-[1.2rem] lg:w-[1rem] xl:w-[1.2rem] w-[1.1rem] mr-2"/>
            <span className={`font-mod font-[1.1rem] ${selected?'text-bg-fill':'text-main-black'}`}>{title}</span>
        </Link>
       
    )  
});


const MobileIcon =  memo(({title,path,selected,id,onClick}:ItemsType&{selected:boolean,onClick:any}) => {
    const _on_click = () => {
        onClick&&onClick(path);
    };  
    return (
        <Disclosure.Button onClick={_on_click} className={`flex flex-row items-center cursor-pointer ${selected?'bg-fill':''} md:px-5 px-4 md:py-2 py-3 rounded-lg`}>
                <img src={get_icon(id,selected)} className="md:w-[1.2rem] w-[1.1rem] mr-2"/>
                <span className={`font-mod font-[1.1rem] ${selected?'text-white':'text-main-black'}`}>{title}</span>
        </Disclosure.Button>
       
    )  
});


export default memo(Header);
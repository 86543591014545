import React from 'react';
import BG from '../../assets/bg/auth_bg.png';

const BgWrapper = ({children}:{children?:any}) => {
    return (
        <div className="flex w-full h-[100vh] justify-between flex-row">
            <div className="w-full lg:w-4/6 h-full flex justify-center items-center">
                {children}
            </div>
            <div className="hidden lg:flex w-2/6 h-full">
               <img src={BG} className='w-full h-full aspect-auto'/>
            </div>
        </div>
    )
};

export default React.memo(BgWrapper);
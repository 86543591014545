import get from "../../utils/requests/get";
import patch from "../../utils/requests/patch";
import post from "../../utils/requests/post";
import { IProfileDetails } from "../../utils/types/profile";



const change_password = (password:string,newPassword:string,confirmNewPassword:string):Promise<boolean> => {
    return new Promise(async(resolve,reject)=>{
        try{
            const res = await post<any>('users/change-password',{password,newPassword,confirmNewPassword});
            resolve(true);
        }catch(e){
            reject(e);
        };
    });
};


const get_profile_details_by_id = (id:string):Promise<IProfileDetails> =>{
    return new Promise(async(resolve,reject)=>{
        try{
            const res = await get<IProfileDetails>(`users/${id}`);
            resolve(res);
        }catch(e){
            reject(e);
        };
    });
}

const get_profile_details = ():Promise<IProfileDetails> => {
    return new Promise(async(resolve,reject)=>{
        try{
            const res = await get<IProfileDetails>('users');
            resolve(res);
        }catch(e){
            reject(e);
        };
    });
};

const save_profile_changes = (email:string,firstName:string,lastName:string,jobTitle:string,companyName:string,aboutMe:string,country:string,pronouns:string,linkedIn:string,timezone:string,profilePicture:string):Promise<IProfileDetails> => {
    return new Promise(async(resolve,reject)=>{
        try{
            const data = {email,firstName,lastName,jobTitle,companyName,aboutMe,country,pronouns,linkedIn,timezone,profilePicture};
            const res = await patch<IProfileDetails>('users', data);
            resolve(res);
        }catch(e){
            reject(e);
        };
    });
};

export {
    change_password,
    get_profile_details,
    save_profile_changes,
    get_profile_details_by_id
}
import { knowledge_base_path, campfire_path, ping_path } from "../../utils/configs/paths";


// eslint-disable-next-line
export default (type:string,c:string,i:string,z?:string):string => {
    // console.log(type,c,i,z);
    switch(type){
        case 'submission_after_due':
            return `/${knowledge_base_path}/${c}/s/${i}`;
        case 'submission':
            return `/${knowledge_base_path}/${c}/s/${i}`;
        case 'session':
            window.open(z||'','_blank');
            return '';
        case 'campfire_tag':
            return `/${campfire_path}/${c}`;
        case 'ping_tag':
            return `/${ping_path}/${i}`;
        case 'knowledge_tag':
            return `/${knowledge_base_path}/${c}/s/${i}`;
        default:
            return '';
    }
};
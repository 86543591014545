import React, { useEffect } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
// import SideBar from '../../common/SideBar';
// import { on_user_login, on_user_logout } from '../../redux/features/auth/auth.slice';
// import { RootState } from '../../redux/store';
import axios from 'axios';
import { RootState } from '../../db/redux/store';
import { AuthContextType,IAuthState } from '../types/auth';
import { on_user_login, on_user_logout } from '../../db/redux/features/auth/auth.slice';
import Footer from '../../common/footer';
import Header from '../../common/header';
// import { logout } from '../../actions/auth';




const AuthContext = React.createContext<AuthContextType>(null!);


const _user_ = createSelector((state:RootState)=>state.auth,(auth)=>auth);

function AuthProvider({children}:{children:React.ReactNode}) {

    const user = useSelector(_user_);
    const dispatch = useDispatch();

    useEffect(()=>{
      const obj = user?{email:user.user?.email,user_id:user.user?.id}:{};
      // console.log(obj);
      // @ts-ignore
      window.Intercom('boot', { 
        app_id: 'nvtaxfys',
        ...obj
      });
      
    },[]);

    // useEffect(()=>{
    //   const _tk = localStorage.getItem('tokens');
    //   const tokens =_tk?JSON.parse(_tk):{token:''};
    //   axios.defaults.headers.common['Authorization'] = `Bearer ${tokens.token}`;
    // },[]);




    const login = async(user: IAuthState):Promise<boolean> =>{
        return new Promise((resolve,reject)=>{
            // axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
            localStorage.setItem("tokens",JSON.stringify({token:user.token,refreshToken:user.refreshToken}));
            dispatch(on_user_login(user));
            resolve(true);
        });
    };  

    const signout = async():Promise<boolean> => {
        return new Promise(async(resolve,reject)=>{
            // logout();
            axios.defaults.headers.common['Authorization'] = '';
            dispatch(on_user_logout());
            localStorage.removeItem("tokens");
            resolve(true);
        });
    };


    const value = { user, login, signout };
    return (
      <AuthContext.Provider value={value}>
        {/* <div className="flex flex-row"> */}
          {/* {user&&user.token&&(
            //   <SideBar />
          )} */}
          <div className={`w-full h-full justify-between flex flex-col ${user&&user.token?'px-5 md:px-[2rem] lg:px-[3rem] xl:px-[7.5rem] py-2.5':''}`}>
            <div className='flex flex-col'>
            {user&&user.token&&(
               <div className='sticky top-0 z-40 -mx-5  md:-mx-[2rem] lg:-mx-[3rem] xl:-mx-[7.5rem]'>
                  <Header />
                </div>
            )}
              {children}
            </div>
            {user&&user.token&&<Footer />}
          </div>
         
        {/* </div> */}
      </AuthContext.Provider>
    );
};

function useAuth(){
  return React.useContext(AuthContext);
};
  
export default AuthProvider;
export { useAuth };
import axios from './api';

const url = process.env.REACT_APP_API_URL||"";


export default <T>(to:string,data?:object,options?:any):Promise<T> => {
    return new Promise(async(resolve,reject)=>{
        try {
            const result = await axios.post(`${url}${to}`,data,options);
            resolve(result.data);
        }catch(e){
            reject(e);
        };
    });
};
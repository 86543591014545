// import React from 'react'
import Loader from '../../common/loader';

interface IButtonProps {
    title:string;
    id?:string;
    loading?:boolean;
};

const Button = ({title,id,loading}:IButtonProps) => {
    return (
        <button type='submit' form={id} className="w-full bg-fill rounded-lg flex items-center justify-center py-[13px] 2xl:py-[15px] cursor-pointer">
           {loading?<Loader stroke='#fff' />:<span className="text-white font-mod text-[15px] lg:text-[18px] 2xl:text-[20px]">{title}</span>}
        </button>
    );
};


export default Button;
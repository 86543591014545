import React from "react";
import { useModal } from "react-modal-hook";
import ProfileDetails from ".";



const ProfileWrapper =React.memo(({children,id,className,span}:{span?:boolean,children:any,id?:string,className?:string}) => {
    const [showModal, hideModal] = useModal(() => (
        <ProfileDetails id={id||''} closeModal={hideModal} />
    ));

    if(!id){
        return children;
    };
    if(span){
        return (
            <span onClick={showModal} className="cursor-pointer text-blue-500 font-mod bg-blue-300/25 rounded-md px-1 pb-1">
                {children}
            </span>
        )
    };
    return (
        <div className={`cursor-pointer ${className}`} onClick={showModal}>{children}</div>
    )
});      

export default ProfileWrapper;
export const login_path = "/login";
export const default_path = "/";
export const forgot_password_path = "/forgot-password";
export const new_password_path = "/new-password";
export const contact_us_path = "/contact-us";
export const welcome_path = "/welcome";

export const cohort_dashboard_path = 'cohort';
export const knowledge_base_path = 'knowledge-base';


export const campfire_path = 'campfire';
export const ping_path = 'pings';
export const my_profile_path = 'profile';

export const cohort_schdule_path = 'cohort-schedule';

export const notification_path = 'notifications';

export const survery_path = 'transformation-tracker';
export const nominate_co_workers = 'nominate';
export const typeform_path = 'fill';

export const file_path = process.env.REACT_APP_API_URL+'files/'||"";
import { useEffect, useState } from "react";
import BgWrapper from "../../components/onboarding/BgWrapper";
import Logo from '../../assets/logo.png';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Input from '../../components/input';
import { useFormik } from "formik";
import {object,string} from 'yup';
import Button from '../../components/button';
import { IValidateTokenResponse } from "../../utils/types/auth";
import { accept_invitation_learner, login, validate_token } from "../../actions/auth";
import Loader from "../../common/loader";
import {m} from 'framer-motion';
import { cohort_dashboard_path, default_path } from "../../utils/configs/paths";
import { toast } from 'react-toastify';
import { useAuth } from "../../utils/providers/auth.provider";
import validate_password from "../../utils/validate_password";
import { useDispatch } from "react-redux";
import { set_cohort_id } from "../../db/redux/features/cohort/cohort.slice";


const validation_schema = object().shape({
    password: string().required('Password required.').min(3, 'Password should have at least 4 characters and include 1 each of uppercase character, lowercase character, numerical digit and special symbol (!?%$)').test({message:'Password should have at least 4 characters and include 1 each of uppercase character, lowercase character, numerical digit and special symbol (!?%$)',
        test:(v:any)=>validate_password(v)}),
    confirm_password: string().required("Your password doesn't match")
}); 


interface IWelcomeForm {
    password:string;
    confirm_password:string;
};



const Welcome = () => {

    const [params,setParams] = useSearchParams();
    const token = params.get('token')?.replaceAll(" ",'+');
    const auth = useAuth();

    const navigate = useNavigate();

    const [is_validated,setValidation] = useState(false);
    // const [has_error,setError] = useState<boolean|string>(false);

    const [data,setData] = useState<IValidateTokenResponse|null>(null)

    const [loading,setLoading] = useState(false);

    const dispatch = useDispatch();


    const get = async() => {
        try{
           
            const data = await validate_token(token||'');
            setData(data);
            setValidation(true);
        }catch(e){
            // setError(true);
            toast.error('Invalid invite, Please try again or contact support.', {position: "top-center", autoClose: 2500, hideProgressBar: false, closeOnClick: true,  pauseOnHover: true, draggable: true, progress: undefined,onClose:()=>{
                navigate(default_path,{replace:true});
            } });
        };
    };  

    useEffect(()=>{
        get();
    },[]);


    const _handle_submission = async(values:IWelcomeForm) => {
        try{
            const {password,confirm_password} = values;
            if(password!==confirm_password){
                 formik.setFieldError('confirm_password',"Your password doesn't match");
                 return false;
            };
            setLoading(true);
            const response = await accept_invitation_learner(token||'',password,confirm_password);
            const login_data = await login({email:data?.email||'',password:password});
            await auth.login(login_data);
            formik.setSubmitting(false);
            if(login_data.user.cohorts.length===1){
                dispatch(set_cohort_id({id:login_data.user.cohorts[0].id,title:login_data.user.cohorts[0].title?login_data.user.cohorts[0].title:''}));
                navigate(`/${cohort_dashboard_path}/${login_data.user.cohorts[0].id}`,{replace:true});
                return true;
            };
            navigate(default_path,{replace:true});
        }catch(e){
            // console.log(e);
            toast.error('Something went wrong, Please try again.', {position: "top-center", autoClose: 2500, hideProgressBar: false, closeOnClick: true,  pauseOnHover: true, draggable: true, progress: undefined});
        };
    }; 

    const formik = useFormik({
        initialValues:{
            password:'',
            confirm_password:''
        },
        onSubmit:_handle_submission,
        validationSchema:validation_schema 
    });


    if(!params.get('token')){
        navigate(default_path,{replace:true});
        return null;
    };


    return (
      <BgWrapper>
            {
                !is_validated?
                <m.div initial={{opacity:0}} exit={{opacity:0}}  transition={{duration:.2}} animate={{opacity:1}} key={'loading'} layout className="w-full h-full flex justify-center items-center flex-col">
                    <Loader stroke={'#333333'} />
                </m.div>
                :  
                <m.div initial={{opacity:0}} exit={{opacity:0}}  transition={{duration:.2}} animate={{opacity:1}} key={'content'} layout className={`w-full h-full flex justify-center items-center flex-col ${loading?'pointer-events-none':'pointer-events-auto'}`}>
                    <img alt="logo" src={Logo} className="w-32 lg:w-44 2xl:w-48"/>
                    <h1 className="font-nib text-[19px] lg:text-[27px] 2xl:text-[29px] text-main-black mt-7">Welcome 👋</h1>
                    <span className="font-mod text-[15px] lg:text-[18px] 2xl:text-[20px] text-main-black">Create a password for your account</span>
                    <span className="font-mod text-[15px] lg:text-[18px] 2xl:text-[20px] text-main-black">{data?.email}</span>
                    <form id='reset_password' onSubmit={formik.handleSubmit} className="md:w-[60%] lg:w-full w-full flex flex-col items-center mt-7  px-5 lg:px-0"> 
                        <div className="flex  w-full lg:w-7/12 xl:w-5/12 2xl:w-4/12 flex-col space-y-5">
                            <Input type='password' id="password" error={formik.touched.password && formik.errors.password} value={formik.values.password} onChange={formik.handleChange} title='Password' placeholder='Enter your new password' />
                            <Input type='password' id="confirm_password" error={formik.touched.confirm_password && formik.errors.confirm_password} value={formik.values.confirm_password} onChange={formik.handleChange} title='Confirm Password' placeholder='Re-enter your password' />
                        </div>

                        <div className="w-full lg:w-7/12 xl:w-5/12 2xl:w-4/12 mt-6">
                            <Button loading={loading} id="reset_password" title='Create new account'/>
                        </div>
                    </form>
                    <span className="font-mod text-[15px] lg:text-[19px] text-center text-main-black mt-6">By creating your account, you agree to our</span>
                    <Link to={'/terms-conditions'} className="">
                        <span className="font-mod text-[15px] lg:text-[19px] text-tx-gray underline cursor-pointer">Terms & Conditions</span>
                    </Link>
                </m.div>
            }
      </BgWrapper>
    );
};


export default Welcome;



const Counter = ({count,c,t}:{count:number,c?:string,t?:string}) => {
    if(count===0){
        return null;
    };
    return (
        <div className={`w-9 h-9 flex items-center justify-center ${c}`}>
            <span className={`text-white absolute font-mod text-[20px] leading-[18px] -mt-1 ${t}`}>{count>9?'+9':count}</span>
            <img className="" src={require('../assets/icons/count_bg_ac.png')}/>
        </div>
    );
};


export default Counter;